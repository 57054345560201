import { initializeApp } from "firebase/app";


const firebaseConfig = {
  apiKey: "AIzaSyCE4D_-MfkHeStP83ii33ocHirj-3UuDW8",
  authDomain: "legal-vault-6bf35.firebaseapp.com",
  projectId: "legal-vault-6bf35",
  storageBucket: "legal-vault-6bf35.appspot.com",
  messagingSenderId: "455346819768",
  appId: "1:455346819768:web:87df02d56408fa67ae5a4d"
};

export const app = initializeApp(firebaseConfig);




